@import '../../scss/_mixins';
@import '../../scss/_variables';

.Footer {
  @include themed() {
    background-color: themed('backgroundColor');
    color: themed('textColor');
    border-color: themed('borderColor');
  }
  border-top: 2px solid black;
  // padding: 0.3em 0.3em 0;
  width: 100%;
  flex-shrink: 0;
  .logo {
    padding: 0.3em 0.3em 0;

    // flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 1em;
    text-transform: uppercase;
    justify-content: center;
    :first-child {
      padding: 0.3em 0.3em 0;

      font-size: 1em;
      font-weight: bold;
    }
    span:nth-of-type(2) {
      padding: 0.3em 0.3em 0;
      color: $logoColor;
      display: inline-flex;
      align-items: center;
    }
  }

  .footer-nav {
    padding: 0.3em 0.3em 0;

    font-size: 1em;
    display: flex;
    justify-content: space-around;

    .menu-item {
      padding: 0.3em 0.3em 0;

      line-height: 30px;
    }
  }
}
