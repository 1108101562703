.CreateUserForm{
  @media screen and (min-width: 800px) {
    margin-top: 10em;
  }
  max-width: 1000px;
  margin: 0 auto;

  h2{
    text-align: center;
  }

  .CreateUserForm{
    
    div{
      max-width: 300px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;    

      input{
        padding: 10px 10px 10px 10px;
        margin: 10px;
      }
    }
    input:hover{
      cursor: pointer;
      background-color: lightgray;
    }
  }
}