@import '_themes.scss';

@mixin themed() {
  @each $theme, $map in $themes {
    $theme-map: map-get($themes, $theme) !global;
    .theme-#{$theme} & {
      @content;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}