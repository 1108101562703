@import '../../scss/_mixins';

.ImageList {
  // Hacky here, b/c of .img-container align-items: center, header gets partially covered with image
  @media screen and (max-width: 320px) {
    padding-top: 30px;
  }
  display: flex;

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    list-style-type: none;
  }

  img {
    max-width: 100%;
    height: auto;
    // animation: fade-in-vertical-zoom-pan 4s ease-in-out 0s forwards;
    // border: 1px solid black;
  }

  li{
    min-width: 320px;
    min-height: 100px;
    border: 2px solid;
    // animation: pulse 2s infinite;
    overflow: visible;
  }

  li:hover{
    box-shadow: 0 0 0 4px;
  }

  .img-container {
    // overflow: hidden;
    line-height: 0;
    // margin: 0.1em 0em;
    display: flex; 
    // border: 1px solid black;

    align-items: center;
    justify-content: center;

    @media screen and (min-width: 800px) {
      margin: 0.5em 0em;
      max-width: 90vw;
      min-height: 350px;
    }
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in-vertical-zoom-pan {
  0% {
    opacity: 0;
    transform: scale(1.4);
    transform-origin: top left;
  }
  15% {
    opacity: 100%;
  }
  100% {
    transform: scale(1);
  }
}

// @keyframes pulse{
//   // 0%{box-shadow: 0 0 0 0 black;}
//   // 50%{box-shadow: 0 0 0 10px black;}
//   // 100%{box-shadow: 0 0 0 0 black;}
//   0%{box-shadow: 0 0 0 0;}
//   50%{box-shadow: 0 0 0 5px;}
//   100%{box-shadow: 0 0 0 0;}
// }
