@import '../../scss/_mixins';

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  // justify-content: center;
  // align-items: center;
  // @include themed() {
  //   background-color: themed('backgroundColor');
  // }
}

html,
body,
#root,
.App__main {
  height: 100%;
  // min-height: 100vh;
  // display: flex;
  // flex-direction: column;
}

a {
  transition: all 0.3s linear;
  @include themed() {
    color: themed('textColor');
  }
  text-decoration: none;
  display: inline-block;
  width: 100%;
  height: 100%;
}

a:hover {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  object-fit: cover;
}

// .img-container {
//   // padding: .5em .2em;
//   margin: 0.5em 0;
// }

.App {
  box-sizing: border-box;
  // height: auto;
}

.underline-h2 {
  font-weight: 1;

  // border-top: solid black 2px;
  // border-right: solid black 1px;
  border-bottom: solid black 2px;

  padding-left: 0.5em;
  margin-right: 50%;
}

.App__main {
  // margin: 0 auto;
  // padding: 0 ;
  flex: 1 0 auto;
  transition: all 0.3s linear;
  @include themed() {
    background-color: themed('backgroundColor');
    color: themed('textColor');
    // border-color: themed('borderColor');
  }
}

.App__main * {
  box-sizing: border-box;
  border-color: inherit;
}

// .img-container {
//   overflow: hidden;
//   line-height: 0;

//   border-top: 1px solid;
//   border-bottom: 2px solid;

//   margin: 0.5em 0em;
//   // padding-left: 50%;

//   @media screen and (min-width: 800px) {
//     border-left: 1px solid themed('textColor');
//     border-right: 1px solid themed('textColor');
//     max-width: 1000px;
//     // margin: 0.5em 5em;
//     // padding: 500px;
//     // height: 500px;
//     // background-color: themed('backgroundColor');
//   }
// }

.menu-item {
  overflow: hidden;
  line-height: 52px;
  &.txt {
    flex-grow: 1;
    text-align: center;
  }
}

@media screen and (min-width: 800px) {
  .menu-item:hover a::before {
    // opacity: 1;
    // transform: rotate(45deg);
    animation: move-to-right 0.5s 0s forwards;
    // display: block;
  }

  .menu-item:hover a::after {
    // transform: rotate(100deg);
    // opacity: 1;
    animation: move-to-left 0.5s 0s forwards;
  }

  .menu-item a::before {
    transform: translate(0px, 0px);
    display: inline-block;
    opacity: 0;
    content: '[';
  }

  .menu-item a::after {
    transform: translate(0px, 0px);
    display: inline-block;
    opacity: 0;
    content: ']';
  }

  @keyframes move-to-right {
    0% {
      transform: translate(0px, 0px);
    }
    100% {
      transform: translate(-20px, 0px);
      opacity: 1;
    }
  }
  @keyframes move-to-left {
    0% {
      transform: translate(0px, 0px);
    }
    100% {
      transform: translate(20px, 0px);
      opacity: 1;
    }
  }
}
