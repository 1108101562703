.ThemeToggle{
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin-top: 2px;
  padding-right: 5px;
  
  .Moon{
  }

  .Sun{
  }
  .sun-moon{
    // overflow: hidden;
    float: right;
    height: 40px;
    width: 40px;
    transition: transform .3s linear;
  }
  
  .sun-moon.day{
    transform: translateY(-100%);
  }

  .sun-moon.night{
    transform: translateY(12%);
  }
}

.ThemeToggle:hover{
  cursor: pointer;
}