@import '../../scss/_variables';

.Nav{
  display: flex;
  align-items: center;
  // margin-right: 10px;

  .menu-icon {
    width: 30px;
    height: 30px;
    position: relative;
    transition: transform $navTransTime linear;
  }
  
  .menu-icon:hover {
    cursor: pointer;
  }
  
  .menu-icon-top,
  .menu-icon-bottom {
    position: absolute;
    height: 1px;
    width: 30px;
    background-color: black;
    border: 1px solid ;
    border-radius: 20px;
  }
  
  .menu-icon-night {
    // background-color: black;
    // border-color: black;
  }
  
  .menu-icon-top {
    bottom: 19px;
    transition: bottom $navTransTime $navTransTime cubic-bezier(0.23, 1, 0.32, 1),
      transform $navTransTime cubic-bezier(0.23, 1, 0.32, 1), 1s linear background-color,
      1s linear border-color;
  }
  
  .menu-icon .menu-icon-top-expanded {
    bottom: 14px;
    transform: rotate(-45deg);
    transition: bottom $navTransTime cubic-bezier(0.23, 1, 0.32, 1),
      transform $navTransTime $navTransTime cubic-bezier(0.23, 1, 0.32, 1),
      1s linear background-color, 1s linear border-color;
  }
  
  .menu-icon-bottom {
    bottom: 9px;
    transition: bottom $navTransTime $navTransTime cubic-bezier(0.23, 1, 0.32, 1),
      transform $navTransTime cubic-bezier(0.23, 1, 0.32, 1), 1s linear background-color,
      1s linear border-color;
  }
  .menu-icon .menu-icon-bottom-expanded {
    bottom: 14px;
    transform: rotate(45deg);
    transition: bottom $navTransTime cubic-bezier(0.23, 1, 0.32, 1),
      transform $navTransTime $navTransTime cubic-bezier(0.23, 1, 0.32, 1),
      1s linear background-color, 1s linear border-color;
  }
  
}
