.ProjectPage {
  // margin: 0px;
  // overflow: hidden;
  // display: flex;
  // flex-direction: row;

  @media screen and (min-width: 800px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  section{
    width: 100%;
  }

//   img {
//     width: 100%;
//     max-width: 1000px;
//     object-position: 50% 25%;
//     object-fit: cover;
//     transform-origin: 15% 20%;
//     // animation: zoom-out 2s ease-in-out 0s forwards;
//     animation: fade-in-vertical-zoom-pan 4s ease-in-out 0s forwards;

//   }

// }

// @keyframes zoom-out {
//   0% {
//     transform: scale(1.4);
//   }
//   100% {
//     transform: scale(1);
//   }
// }

// @keyframes fade-in-vertical-zoom-pan {
//   0% {
//     opacity: 0;
//     // transition: transform .5s ease-in-out;
//     transform: scale(1.4);
//     transform-origin: top left;
//   }
//   15%{
//     opacity: 100%;
//   }
//   100% {
//     transform: scale(1);
//   }
}