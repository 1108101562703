.ImgLoader{
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  @media screen and (min-width: 800px) {
    height: 400px;
  }
.House{
  // border: 1px solid black;
  padding-right: 10px;
  animation: fade-in .5s linear;

  .House-gear{
    transform-origin: 50% 55.5%;
    animation: spin 2s linear infinite;

    @keyframes spin {
      100% {
        transform:rotate(360deg);
      }
    }
  }

}
  // max-width: 400px;
  // min-width: 800px;
  // height: auto;
}

@keyframes fade-in{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 100%;
  }
}