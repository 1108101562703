@import '../../scss/_mixins';
@import '../../scss/_variables';

// $max-width: 1600px;
$max-width: none;

.Header {
  display: flex;
  flex-flow: row wrap;
  transition: all 0.3s linear;
  
  // @media screen and (min-width: 800px) {
  //   padding-bottom: 3em;
  // }

  @include themed() {
    background-color: themed('backgroundColor');
    color: themed('textColor');
    border-color: themed('borderColor');
  }
  justify-content: center;

  // .header-top, .menu-drop-down{
  // } 

  .header-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    // padding: 0.3em 0.3em 0 0.3em;
    border-bottom: 2px solid;
    padding: 10px;

    @media screen and (min-width: 800px) {
      padding: 20px;
      max-width: $max-width - 40px; // 20px padding
    }

    .logo {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.3em;
      text-transform: uppercase;
      text-align: center;
      // letter-spacing: 1px;
      .logo-first {
        font-size: 1.3em;
        font-weight: bold;
      }
      .logo-second {
        display: inline-flex;
        align-items: center;
        color: $logoColor;
      }
      div{
        span:nth-of-type(1){
          opacity: 0;
          animation: fade-in-up 1s forwards 1s;
          display: inline-block;
          // color: red;
        }
        span:nth-of-type(2){
          opacity: 0;
          animation: fade-in-up 1s forwards 2s;
          display: inline-block;
        }
        span:nth-of-type(3){
          opacity: 0;
          animation: fade-in-up 1s forwards 3s;
          display: inline-block;
        }

        font-size: .7em;
        @media screen and (min-width: 800px) {
          font-size: .8em;
        }

        @keyframes fade-in-up{
          0%{
            opacity: 0;
            transform: translateY(30px);
          }
          100%{
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }
    }

    .right {
      display: flex;
    }

    @media screen and (min-width: 800px) {
      nav{
        display: flex;
        .menu-item{
          a {
            padding-right:100px;
            padding-right:100px;
            width: 100%;
          }
        }
      }
    }
  }

  .line-break {
    // width: 100%;
  }

  overflow: hidden;

  .menu-drop-down {
    max-width: $max-width;

    transition: max-height $navTransTime linear;
    max-height: 100px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: -2px;
    border-bottom: 2px solid;
    border-color: inherit;
    font-size: 1.4em;
    @include themed() {
      // background-color: themed('borderColor');
      // color: themed('textColor');
      // border-color: themed('borderColor');
    }

    // .menu-item {
    //   font-size: 1.4em;
    //   overflow: hidden;
    //   line-height: 52px;
    // }
  }
  .hidden {
    transition: max-height $navTransTime linear;
    // overflow: hidden;
    max-height: 0;
  }

  .menu-icon-top,  .menu-icon-bottom {
    
  // transition: background-color 0.3s linear;
    @include themed() {
      background-color: themed('borderColor');
    }
  }
}
