.ContactPage {
  @media screen and (min-width: 800px) {
    margin-top: 10em;
  }
  max-width: 1000px;
  margin: 0 auto;

  table {
    margin: 1em;

    td {
      height: 2em;
      vertical-align: top;
    }

    td:nth-child(odd){
      // color: red;
      width: 5em;
    }

    th{
      text-align: left;
      font-size: 1.3em;
    }
  }
}
